import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <div>
    <h1>NOT FOUND</h1>
    <div><a href="/">Back to Home page</a></div>
    </div>
)

export default NotFoundPage
